<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-card>
    <ejs-toast
      id="toast_type_startSend"
      ref="toastRef_startSend"
      :position="{ X: 'Right' }"
      :timeOut="0"
    />
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <modal-plu-repository-list
      ref="modal-plu-repository-list"
      @load-plu-repository="loadPluRepository"
      :id-punto-vendita="null"
    />
    <div class="col-lg-12 control-section mt-2 p-0">
      <div class="content-wrapper">
        <b-row class="mb-2">
          <b-col class="d-flex justify-content-end">
            <!-- <a
              class="mr-2"
              :href="HelloWorld"
              download
            >Scarica excel</a> -->
            <b-button
              variant="secondary"
              class="mr-2"
              @click="(() => $refs['modal-plu-repository-list'].openModal())"
            >Riprendi precendenti</b-button>
            <b-button
              variant="info"
              class="mr-2"
              :disabled="salvaSessioneDisabled"
              @click="saveData"
            >Salva</b-button>
            <b-button
              variant="primary"
              @click="sendToBalance"
              :disabled="salvaSessioneDisabled"
            >Invia alle Bilance</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <ejs-grid
              id="overviewgrid"
              ref="overviewgrid"
              locale="it-IT"
              :data-source="rows"
              :selection-settings="selectionSettings"
              :edit-settings="editSettings"
              :filter-settings="filterOptions"
              :allow-selection="false"
              :allow-sorting="false"
              :allow-filtering="false"
              :allow-resizing="true"
              :enable-virtualization="false"
              :allow-reorder="false"
              height="600"
              :allow-pdf-export="true"
              :allow-excel-export="true"
              row-height="38"
              :enable-hover="true"
              :enable-header-focus="true"
              :toolbar="toolbar"
              :toolbar-click="toolbarClick"
              :action-complete="actionTableComplete"
              :action-begin="actionTableBegin"
              :allow-grouping="false"
              :allow-paging="true"
              :page-settings="pageSettings"
              :data-bound="dataBound"
              :command-click="commandColumm"
              :detailTemplate="detailTemplate"
              class="e-grid"
              :query-cell-info="prova"
            >
              <!-- <e-columns>
                <e-column
                  type="checkbox"
                  :allow-filtering="false"
                  :allow-sorting="false"
                  width="60"
                />
                <e-column
                  field="id"
                  :visible="false"
                  header-text="ID"
                  :is-primary-key="true"
                  width="130"
                />
                <e-column
                  field="Plu"
                  header-text="Plu"
                  width="100"
                  :allow-editing="false"
                  clip-mode="EllipsisWithTooltip"
                />
                <e-column
                  field="nome"
                  header-text="Nome Prodotto"
                  width="200"
                  :allow-editing="false"
                />
                <e-column
                  field="Listino.nome"
                  header-text="Listino"
                  width="150"
                  edit-type="dropdownedit"
                  :edit="dpParams"
                  :allow-editing="true"
                />
                <e-column
                  field="valoreBase"
                  header-text="Valore Base"
                  width="150"
                  :allow-editing="true"
                />
                <e-column
                  field="Domicilio"
                  header-text="Domicilio"
                  width="150"
                  :filter="filter"
                />
                <e-column
                  field="PaghiPoco"
                  header-text="Paghi Poco"
                  width="150"
                  :filter="filter"
                />
                <e-column
                  field="Vivo"
                  header-text="Vivo"
                  width="150"
                  :filter="filter"
                />
                <e-column
                  field="Sisa"
                  header-text="Sisa"
                  width="150"
                  :filter="filter"
                />
                <e-column
                  field="Crai"
                  header-text="Crai"
                  width="150"
                  :filter="filter"
                />
                <e-column
                  field="Azioni"
                  :lock-column="true"
                  header-text="Azioni"
                  width="240"
                  :template="cTemplate"
                  :allow-editing="false"
                  :commands="commands"
                  :allow-sorting="false"
                  :allow-filtering="false"
                />
              </e-columns> -->
            </ejs-grid>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-card>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-str */
/* eslint-disable vue/no-side-effects-in-computed-properties */

import Vue from 'vue'
import {
  GridPlugin, Sort, Filter, Selection, PdfExport, ExcelExport, Toolbar, Edit, Page, ContextMenu, CommandColumn, Resize, Scroll, Search, DetailRow,
} from '@syncfusion/ej2-vue-grids'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import { DropDownListPlugin, DropDownList } from '@syncfusion/ej2-vue-dropdowns'
import { getListini } from '@/@core/api-service/catalog/priceList'
import { getProdotti } from '@/@core/api-service/catalog/products'
import { getPlui, setPluRepository, invioPluRepository } from '@/@core/api-service/devices/Plu'
import ModalPluRepositoryList from './components/ModalPluRepositoryList.vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { italian } from '@/@core/grid-translation/it-IT'
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { BCard, BButton, BRow, BCol, VBToggle, BBadge, } from 'bootstrap-vue'
import HelloWorld from '../../../public/HelloWorld.xlsx'

setCulture('it-IT');
Vue.use(DropDownListPlugin)
L10n.load(italian)
Vue.use(GridPlugin)
Vue.use(ToastPlugin)

let listini = []
/* listini.unshift({ id: 'COSTO', nome: 'COSTO' })
listini.unshift({ id: 'PREZZO', nome: 'PREZZO' })
listini.unshift({ id: 'DEFAULT', nome: 'DEFAULT' }) */
let prodotti = []
getProdotti().then(res => {
  if (res.data.esito === 'OK') {
    prodotti = [...res.data.prodotti]
  }
})
//Vue.use(DropDownListPlugin)
function compileFormula(listino, costo, prezzoVendita) {
  let valoreFormula = 0
  if(listino.valorePartenza === 'COSTO') {
    valoreFormula = costo
    if(listino.movimentazionePercentuale === '+') {
      valoreFormula = Number(costo) + Number((percentage(Number(costo), Number(listino.valorePercentuale))))
    } else if (listino.movimentazionePercentuale === '-') {
      valoreFormula = Number(costo) - Number((percentage(Number(costo), Number(listino.valorePercentuale))))
    }
    if(listino.movimentazioneEuro === '+') {
      valoreFormula += Number(listino.valoreEuro)
    }else if(listino.movimentazioneEuro === '-') {
      valoreFormula -= Number(listino.valoreEuro)
    }
  } else if(listino.valorePartenza === 'PREZZO') {
    valoreFormula = prezzoVendita
    if(listino.movimentazionePercentuale === '+') {
      valoreFormula = Number(prezzoVendita) + Number((percentage(Number(prezzoVendita), Number(listino.valorePercentuale))))
    } else if (listino.movimentazionePercentuale === '-') {
      valoreFormula = Number(prezzoVendita) - Number((percentage(Number(prezzoVendita), Number(listino.valorePercentuale))))
    }
    if(listino.movimentazioneEuro === '+') {
      valoreFormula += Number(listino.valoreEuro)
    }else if(listino.movimentazioneEuro === '-') {
      valoreFormula -= Number(listino.valoreEuro)
    }
  }
  return parseFloat(valoreFormula).toFixed(2)
  // return round2Decimals(valoreFormula)

}
function percentage(partialValue, totalValue) {
  return (totalValue / 100) * partialValue;
}
function round2Decimals(theform) {
    var num = theform
    var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    return with2Decimals
}
let country= [
        { nome: 'Orata', id: '1' },
        { nome: 'Spigola', id: '2' },
        { nome: 'Alici', id: '3' },
        { nome: 'Pesce Spada', id: '4' },
    ];
let dropdownObj, elem;
export default Vue.extend({
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    ModalPluRepositoryList,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data: () => ({
    HelloWorld: HelloWorld,
    salvaSessioneDisabled: true,
    // listinoRules: { required: true, minLength: [(args) => {return args['value'].length >= 5;}, 'Obbligatorio'] },
    listiniDefault: [
      { id: 'DEFAULT', nome: 'DEFAULT'},
      { id: 'COSTO', nome: 'COSTO'},
      { id: 'PREZZO', nome: 'PREZZO'},
    ],
    listini: [],
    prodotti: [],
    dpParams: {
      create: function() {
        elem = document.createElement('input')
        return elem
      },
      read: () => {
        return dropdownObj.value
      },
      destroy: () => {
        dropdownObj.destroy()
      },
      write: async args => {
        await getListini().then(res => {
          // console.log('res listini prima di ciaaa', res)
          if (res.data.esito === 'OK') {
            listini = [...res.data.listini]
            listini.unshift({ id: 'COSTO', nome: 'COSTO' })
            listini.unshift({ id: 'PREZZO', nome: 'PREZZO' })
            listini.unshift({ id: 'DEFAULT', nome: 'DEFAULT' })
            // console.log(listini)
          }
        })
        dropdownObj = new DropDownList({
          dataSource: listini,
          value: args.rowData['Listino'],
          fields: { text: 'nome', value: 'nome' },
          change: function () {
            // console.log('dentro changeee', args, listini.length)
            let listiniColonneTemplateValue = []
            const listinoSlice = listini.slice(3)
            // console.log()
            listinoSlice.forEach(listino => {
              const colonna = document.getElementById("overviewgrid").querySelector('#' + 'overviewgrid' + listino.nome).ej2_instances[0]
              listiniColonneTemplateValue.push(colonna)
            })
            let formElementListinoNome = document.getElementById("overviewgrid").querySelector('#' + 'overviewgrid' + 'listino').ej2_instances[0]
            // console.log('formElementListinoNome', formElementListinoNome.value)
            const listinoNome = formElementListinoNome.value
            const listinoServer = listinoSlice.find(listino => listino.nome === listinoNome)
            const prodottoServer = prodotti.find(prodotto => prodotto.nome === args.rowData.nome)
            const { prezzoVendita, costo } = prodottoServer
            // console.log(listinoNome, prezzoVendita, costo)
            // console.log('dentro listiniColonneTemplateValue', listiniColonneTemplateValue, listiniColonneTemplateValue.length)

            if (listinoNome === 'PREZZO') {
              listiniColonneTemplateValue.forEach(el => {
                el.value = prezzoVendita
              })
            } else if(listinoNome === 'COSTO') {
              listiniColonneTemplateValue.forEach(el => {
                el.value = costo
              })
            } else if (listinoNome === 'DEFAULT') {
              listiniColonneTemplateValue.forEach(el => {
                el.value = ''
              })
            } else {
              listiniColonneTemplateValue.forEach(el => {
                el.value = compileFormula(listinoServer, costo, prezzoVendita)
              })
            }
          }
        })
        dropdownObj.appendTo(elem)
      },
    },
    cTemplate: function () {
      return { template : Vue.component('columnTemplate',{
        template: `<div class="image">
              <img :src="image" :alt="altImage"/>
          </div>`,
          data: function() {
              return {
                  data: {}
              }
          },
          computed: {
              image: function() {
                  return './' + this.data.EmployeeID + '.png'
              },
              altImage: function() {
                  return this.data.EmployeeID
              }
          }
        })
      }
    },
    detailTemplate() {
      return {
        template: Vue.component('templateAttivo', {
          components: {
            BBadge,
          },
          data() { return { data: {}, item: ['Reparto1', 'Reparto2', 'Reparto3', 'Reparto4', 'Reparto5'] } },
          template: `
          <div id="producter" class="">
            <h6><b>Reparti Associati: </b></h6>
            <b-badge v-for="(el, index) in data.reparti" :key="index" class="mr-2"> {{ el.codice.concat('-').concat(el.nome) }} </b-badge>
          </div>`,
        }),
      }
    },
    serialBatch: {},
    commands: [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
        /* { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }, */
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } },
      ],
    contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
      'Copy', 'Edit', 'Save', 'Cancel',
      'PdfExport', 'ExcelExport', 'CsvExport', 'FirstPage', 'PrevPage',
      'LastPage', 'NextPage',  'Group', 'Ungroup', {text: 'Dettagli', target: '.e-content', id: 'Dettagli', iconCss: 'e-icons e-settings' }],
    pageSettings: { pageSize: 200, pageSizes: [25,50,100,200], pageCount: 5 },
    showModalSettingPdfExport: false,
    editSettings: { allowFiltering: true, allowEditing: true, allowAdding: true, allowDeleting: true },
    toolbar: ['ExcelExport', 'PdfExport', 'CsvExport',  'Print'],
    toolbarOptions: ['ExcelExport'],
    ddData: [{ value: 1000, text: '1,000 Rows and 11 Columns' }, { value: 10000, text: '10,000 Rows and 11 Columns' }],
    ddValue: 50,
    stTime: null,
    loadTime: null,
    dReady: false,
    isDataChanged: true,
    fields: { text: 'text', value: 'value' },
    filterOptions: {
      type: 'Menu',
    },
    filter: {
      type: 'CheckBox',
    },
    selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
    rows: [],
    plui: [],
    listini: [],
  }),
  computed: {
    getTradeData() {
      let dataCount = this.ddValue
      const employees = [
        'Orata', 'Spigola', 'Alici', 'Pesce Spada', 'Orata2', 'Gamberetti', 'Gamberi', 'Sgombro', 'Tonno', 'Alici Giganti', 'Ricciola']
      const designation = ['Manager', 'CFO', 'Designer', 'Developer', 'Program Directory', 'System Analyst', 'Project Lead']
      const mail = ['sample.com', 'arpy.com', 'rpy.com', 'mail.com', 'jourrapide.com']
      const location = ['UK', 'USA', 'Sweden', 'France', 'Canada', 'Argentina', 'Austria', 'Germany', 'Mexico']
      const status = ['Active', 'Inactive']
      const trustworthiness = ['Perfect', 'Sufficient', 'Insufficient']
      const Listino = ['Listino1', 'Listino2', 'Listino13']
      const tradeData = []
      const address = ['59 rue de lAbbaye', 'Luisenstr. 48', 'Rua do Paço, 67', '2, rue du Commerce', 'Boulevard Tirou, 255',
        'Rua do mailPaço, 67', 'Hauptstr. 31', 'Starenweg 5', 'Rua do Mercado, 12',
        'Carrera 22 con Ave. Carlos Soublette #8-35', 'Kirchgasse 6',
        'Sierras de Granada 9993', 'Mehrheimerstr. 369', 'Rua da Panificadora, 12', '2817 Milton Dr.', 'Kirchgasse 6',
        'Åkergatan 24', '24, place Kléber', 'Torikatu 38', 'Berliner Platz 43', '5ª Ave. Los Palos Grandes', '1029 - 12th Ave. S.',
        'Torikatu 38', 'P.O. Box 555', '2817 Milton Dr.', 'Taucherstraße 10', '59 rue de lAbbaye', 'Via Ludovico il Moro 22',
        'Avda. Azteca 123', 'Heerstr. 22', 'Berguvsvägen  8', 'Magazinweg 7', 'Berguvsvägen  8', 'Gran Vía, 1', 'Gran Vía, 1',
        'Carrera 52 con Ave. Bolívar #65-98 Llano Largo', 'Magazinweg 7', 'Taucherstraße 10', 'Taucherstraße 10',
        'Av. Copacabana, 267', 'Strada Provinciale 124', 'Fauntleroy Circus', 'Av. dos Lusíadas, 23',
        'Rua da Panificadora, 12', 'Av. Inês de Castro, 414', 'Avda. Azteca 123', '2817 Milton Dr.']
      const employeeimg = ['usermale', 'userfemale']
      if (typeof dataCount === 'string') {
        dataCount = parseInt(dataCount, 10)
      }
      for (let i = 1; i <= dataCount; i++) {
        const code = 10000
        tradeData.push({
          ID: code + i,
          Prodotto:
                        `${employees[Math.floor(Math.random() * employees.length)]} ${employees[Math.floor(Math.random() * employees.length)]}`,
          Plu: Math.floor(Math.random() * designation.length),
          Domicilio: Math.floor(Math.random() * designation.length),
          PaghiPoco: Math.floor(Math.random() * designation.length),
          Vivo: Math.floor(Math.random() * designation.length),
          Sisa: Math.floor(Math.random() * designation.length),
          Crai: Math.floor(Math.random() * designation.length),
          Listino: 'Default',
        })
      }
      this.stTime = performance.now()
      return tradeData
    },
    prodottiComputed() {
      return this.prodotti
    },
    computedColumn() {
      if(this.listini.length > 0 && this.plui.length > 0) {
        // console.log('dentro computed')
        return []
      }
      return []
    },
  },
  async created() {
    const plu = await getPlui(JSON.stringify({ escludiAssociati: 'Y'})).then(res => {
      // console.log('getPlui', res)
      if (res.data.esito === 'OK') {
        // console.log('sono su ok getPlui')
        this.plui = [...res.data.plu]
        // this.rows = [...res.data.plu]
      }
    }).catch(e => e)
    const listini = await getListini().then(res => {
      // console.log('res listini', res)
      if (res.data.esito === 'OK') {
        this.listini = [...res.data.listini]
        // this.$refs.overviewgrid.refresh()
      }
    })
    // plu
    // listini
    // console.log('3')
    // console.log('this.plui', this.plui)
    // console.log('this.listini', this.listini)
    const internalPlu = [...this.plui]
    internalPlu.forEach((plu, index) => {
      Vue.set(plu, 'uniqueID', index)
    })
    // console.log('internalPLu', internalPlu)
    const pluFilterd = []
    internalPlu.forEach(internalSinglePlu => {
      const reparti = []
      internalPlu.forEach(pluAgain => {
        if (internalSinglePlu.id === pluAgain.id && internalSinglePlu.uniqueID !== pluAgain.uniqueID) {
          reparti.push(internalSinglePlu.reparti[0], pluAgain.reparti[0])
        } else {
          reparti.push(internalSinglePlu.reparti[0])
        }
      })
      pluFilterd.push({ ...internalSinglePlu, reparti: [...new Set(reparti)]})
    })
    pluFilterd.forEach(plu => {
      Vue.delete(plu, 'uniqueID')
    })
    const uniqueIds = [];

    const unique = pluFilterd.filter(element => {
      const isDuplicate = uniqueIds.includes(element.id)
      if (!isDuplicate) {
        uniqueIds.push(element.id)
        return true
      }
    })
    // console.log('unique', unique)
    // mappo tutti i listini
    const listiniColumn = this.listini.map(listino => ({ headerText: listino.nome, field: listino.nome, width: '200', allowResizing: true,  autoFit: false, clipMode:'EllipsisWithTooltip', editType: 'NumericTextBox', params: { decimals: 2, format: 'N'} , validationRules: { min: ['0', 'Il valore deve essere maggiore di 0'],  max: ['1000', 'Il valore non può essere maggiore di 1000'] } }))
    // const listiniColumn = this.listini.map(listino => ({ headerText: listino.nome, field: listino.nome, width: '200', allowResizing: true,  autoFit: false, clipMode:'EllipsisWithTooltip', editType: 'numericedit', params: { decimals: 2, format: 'N'} , validationRules: { min: ['0', 'Il valore deve essere maggiore di 0'],  max: ['1000', 'Il valore non può essere maggiore di 1000'] } }))
    // console.log('listiniColumn', listiniColumn)
    const grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0]
    var obj = [
      { headerText: "Azioni", width: '120',  commands: this.commands },
      { headerText: "id", field: 'id', width: '50', isPrimaryKey: true, visible: false, allowEditing: false },
      { headerText: "tasto", field: 'tasto', width: '80', allowEditing: false },
      // { headerText: "reparti", field: 'reparti', width: 80 },
      { headerText: "nome", field: 'nome', width: '120', allowEditing: false },
      { headerText: "idProdotto", field: 'idProdotto', width: '120', allowEditing: false, visible: false },
      { headerText: "listino", field: 'listino', width: '120', edit: this.dpParams, editType: 'dropdownedit', },
      ...listiniColumn,
    ]
    // setto tutte le chiavi dei listini nelle row
    let objkeyNameListiniRow = listiniColumn.map(el => ({ [el.field]: '' }))
    objkeyNameListiniRow = Object.assign({}, ...objkeyNameListiniRow);
    // console.log('objkeyNameListiniRow', objkeyNameListiniRow)
    grid.columns = obj
    // grid.refreshColumns()
    // console.log('grid', grid)
    // arrayAppoggio.forEach(el => {
    unique.forEach(el => {
      let singleRecord = {
        id: el.id,
        reparti: el.reparti,
        // listino:
        tasto: el.tasto,
        nome: el.nome,
        idProdotto: el.idProdotto,
        ...objkeyNameListiniRow,
      }
      this.rows.push(singleRecord)
    })
    // grid.refreshColumns()
    // console.log(this.$refs.overviewgrid.getDataModule())
  },
  methods: {
    prova(args) {
      // console.log(args)
      const data = this.$refs.overviewgrid.getDataModule()
      const dataSave = {
        id: 0,
        repository: data.dataManager.dataSource.json,
      }
      dataSave.id = 0
      // console.log('saveData', data.dataManager.dataSource.json)
      const prova = dataSave.repository.map(el => {
         for (const [key, value] of Object.entries(el)) {
           if(!['id', 'reparti', 'tasto', 'nome', 'idProdotto', 'listino'].some(el => el === key )) {
              // console.log(value)
              value =  parseFloat(value).toFixed(2)
              value = value === 'NaN' ? '' : value
              el[key] = value
              // const newKeyValue = { [key]}
              // console.log(`${key}: ${value}`);
            }
          }
        return el
      })
      dataSave.repository = prova
    },
    loadPluRepository(repository) {
      console.log('repository', repository)
      // repository = JSON.parse(repository)
      const arrayTemp = []
      let dataSourceTable = this.$refs.overviewgrid.getDataModule()
      dataSourceTable = dataSourceTable.dataManager.dataSource.json
      repository.forEach(el => {
        const elementInTable = dataSourceTable.findIndex(tableElement => tableElement.idProdotto === el.idProdotto && tableElement.tasto === el.tasto)
        const elementInRepository = repository.findIndex(repositoryElement => repositoryElement.idProdotto === el.idProdotto && repositoryElement.tasto === el.tasto)
        if (elementInTable && elementInRepository) {
          const result = dataSourceTable[elementInTable].reparti.map(rep => {
            return { esiste: repository[elementInRepository].reparti.some(({id}) => id == rep.id) }
          })
          if (result.every(el => el.esiste === true)) {
            arrayTemp.push(repository[elementInRepository])
          }
        }
      })
      if (arrayTemp.length === 0) {
        this.$refs.toastRef.show({
          title: 'Errore Caricamento Configurazione', content: 'Hai selezionato una configurazione diversa da quella attuale', cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
        })
        return
      }
      let arrayToSpread = []
      arrayToSpread = [...this.rows]
      this.rows.forEach((el, index) => {
        const indexRow = arrayTemp.findIndex(elTemp => elTemp.idProdotto === el.idProdotto)
        if (indexRow >= 0) {
          this.rows[index] = arrayTemp[indexRow]
          arrayTemp.splice(indexRow, 1)
        }
      })
      arrayToSpread = [...this.rows]
      this.rows.length = 0
      this.rows = [...arrayToSpread]
      this.rows = [...repository]
    },
    compareKeys(a, b) {
      var aKeys = Object.keys(a).sort()
      var bKeys = Object.keys(b).sort()
      return JSON.stringify(aKeys) === JSON.stringify(bKeys)
    },
    areEqual(array1, array2) {
      if (array1.length === array2.length) {
        return array1.every((element, index) => {
          if (element.id === array2[index].id) {
            return true
          }
          return false
        })
      }
      return false
    },
    created() {
    },
    saveData() {
      const data = this.$refs.overviewgrid.getDataModule()
      const dataSave = {
        id: 0,
        repository: data.dataManager.dataSource.json,
      }
      dataSave.id = 0
      // console.log('saveData', data.dataManager.dataSource.json)
      const prova = dataSave.repository.map(el => {
         for (const [key, value] of Object.entries(el)) {
            if(!['id', 'reparti', 'tasto', 'nome', 'idProdotto', 'listino'].some(el => el === key )) {
              value =  parseFloat(value).toFixed(2)
              value = value === 'NaN' ? '' : value
              el[key] = value
            }
          }
        return el
      })
      dataSave.repository = prova
      dataSave.id_puntovendita = null
      // console.log('saveData2', JSON.stringify(dataSave))
      // console.log('saveData2', JSON.stringify(dataSave))
      // setPluRepository(JSON.stringify([dataSave])).then(res => console.log(res)).catch(e => e)
            console.log(JSON.stringify([dataSave]))

      setPluRepository(JSON.stringify([dataSave])).then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          this.$refs.toastRef.show({
          title: 'Sessione Salvata', content: 'Salvataggio sessione avvenuta con successo', cssClass: 'e-toast-success', icon: 'e-success toast-icons',
        })
        }
      }).catch(e => e)
    },
    sendToBalance() {
      const data = this.$refs.overviewgrid.getDataModule()
      // console.log(data)
      const dataSave = {
        id: 0,
        repository: data.dataManager.dataSource.json,
      }
      dataSave.id = 0
      // console.log('saveData', data.dataManager.dataSource.json)
      // console.log('saveData2', JSON.stringify(dataSave.repository))
      try {
        this.$refs.toastRef_startSend.show({
          title: 'Invio alle Bilance Avviato', content: 'Invio in corso...', cssClass: 'e-toast-info', icon: 'e-info toast-icons',
        })
        this.salvaSessioneDisabled = true
        const repository = data.dataManager.dataSource.json.map(el => ({ ...el, id_puntovendita: null }))
        invioPluRepository(JSON.stringify(repository)).then(res => console.log(res)).catch(() => {
          this.$refs.toastRef_startSend.hide('All')
          this.salvaSessioneDisabled = false
          this.$refs.toastRef.show({
            title: 'Inviato', content: 'Prezzi Inviati alle Bilance', cssClass: 'e-toast-success', icon: 'e-success toast-icons',
          })
        })
      } catch {
        this.$refs.toastRef_startSend.hide('All')
        this.salvaSessioneDisabled = false
        this.$refs.toastRef.show({
          title: 'Inviato', content: 'Prezzi Inviati alle Bilance', cssClass: 'e-toast-success', icon: 'e-success toast-icons',
        })
      }
    },
    getListinis() {
      getListini().then(res => {
          // console.log('getlistini', res)
          if (res.data.esito === 'OK') {
            this.listini = [...res.data.listini]
            this.listini.unshift(...this.listiniDefault)
            // console.log(this.listini)
            this.prodotti = this.prodotti.map(el => ({ ...el, listini: [...this.listini]}))
            // this.$refs.overviewgrid.refresh()
          }
      }).catch(e => e)
      // console.log('provaaa')
    },
    dataBound(args) {
      // console.log(this.$refs.overviewgrid.getColumnIndexByField('Azioni'))
      // console.log(this.$refs.overviewgrid)
      // this.$refs.overviewgrid.autoFitColumns()
      // this.$refs.overviewgrid.ej2Instances.columns[0].isPrimaryKey = true;
    },
    load() {
    },
    commandColumm(args) {

    },
    contextMenuClick(args) {
        // console.log('contextMenuClick', args)
    },
    closeModal() {
      this.showModalSettingPdfExport = false
    },
    actionTableComplete(args) {
      if (args.requestType === 'save') {
        this.salvaSessioneDisabled = false
      }
      if (args.requestType === 'save') {
        // if (args.data.listino === 'DEFAULT') {
        if (args.requestType === 'save') {
        let row = { ...args.data }
          for (const [key, value] of Object.entries(args.data)) {
            if(!['id', 'reparti', 'tasto', 'nome', 'idProdotto', 'listino'].some(el => el === key )) {
              value =  parseFloat(value).toFixed(2)
              value = value === 'NaN' ? '' : value
              row[key] = value
              // const newKeyValue = { [key]}
              // console.log(`${key}: ${value}`);
              // console.log('rrrooow', row)
              this.$refs.overviewgrid.setRowData(args.data.id, row)
              // this.$refs.overviewgrid.setCellValue(args.data.id, key, value)
              // console.log(this.$refs.overviewgrid.getDataModule())
            }
          }
        }
      }
    },
    actionTableBegin(args) {
      if (args.requestType === 'beginEdit') {
        this.salvaSessioneDisabled = true
      }
      this.$refs.overviewgrid.getColumns(true)
      // this.$refs.overviewgrid.columns.rows.forEach(el => el.cssClass = 'widthCol')
      // this.$refs.overviewgrid.refreshColumns()
    },
     toolbarClick(args) {
        // console.log(args)
        if (args.item.id === 'Dettagli') {
          alert("Custom Toolbar Click...");
        }
        if (args.item.id === 'overviewgrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
          this.showModalSettingPdfExport = true
            const exportProperties = {
                //you can customize the name as per your requirement
                fileName: `Plu_${moment().format('DD-MM-YYYY')}.pdf`,
                pageOrientation: 'Landscape',
                 pageSize: 'A3'
             }
            // this.$bvModal.show('setting-pdf-export-modal')
            this.$refs.overviewgrid.pdfExport(exportProperties)
            // this.$refs.overviewgrid.pdfExport(exportProperties)
        }
        if (args.item.id === 'overviewgrid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
            const exportProperties = {
                //you can customize the name as per your requirement
                fileName: `Plu_${moment().format('DD-MM-YYYY')}.xlsx`,
             }
            this.$refs.overviewgrid.excelExport(exportProperties)
        }
        if (args.item.id === 'overviewgrid_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
            const exportProperties = {
                //you can customize the name as per your requirement
                fileName: `Plu_${moment().format('DD-MM-YYYY')}.csv`,
             }
            this.$refs.overviewgrid.csvExport(exportProperties)
        }
     },
  },
  provide: {
    // grid: [Toolbar, PdfExport, ExcelExport, Filter, Selection, Sort, Edit, Page, ColumnChooser, RowDD, ContextMenu, CommandColumn, Resize, Scroll, Filter, Reorder],
    grid: [Toolbar, PdfExport, ExcelExport, Filter, Selection, Sort, Page, ContextMenu, CommandColumn, Resize, Scroll, Edit, Search, DetailRow],
  },

})

</script>
<style scoped>
/* @import "../../../../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap.css"; */
th.e-headercell.e-ellipsistooltip.e-resized {
  width:  200px !important;
}
.widthCol {
  width: 200px !important;
}
</style>
