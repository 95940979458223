<template>
  <div>
    <b-modal
      id="modal-plu-repository-list"
      ref="modal-plu-repository-list"
      centered
      :visible="show"
      size="lg"
      hide-footer
      title="Riprendi Sessioni Precedenti"
      @change="show = !show"
    >
      <ejs-grid
        id="overviewgridRepository"
        ref="overviewgridRepository"
        locale="it-IT"
        :data-source="pluRepositories"
        :selection-settings="selectionSettings"
        :edit-settings="editSettings"
        :filter-settings="filterOptions"
        :show-column-chooser="false"
        :allow-selection="false"
        :allow-sorting="true"
        :allow-filtering="true"
        :allow-resizing="true"
        :enable-virtualization="false"
        :allow-row-drag-and-drop="false"
        height="600"
        :allow-pdf-export="true"
        :allow-excel-export="true"
        row-height="38"
        :enable-hover="false"
        :enable-header-focus="true"
        :allow-grouping="false"
        :allow-paging="true"
        :page-settings="pageSettings"
        :context-menu-items="contextMenuItems"
        :context-menu-click="contextMenuClick"
        :data-bound="dataBound"
        :command-click="commandColumm"
      >
        <e-columns>
          <e-column
            field="id"
            :visible="false"
            header-text="ID"
            :is-primary-key="true"
            width="130"
          />
          <e-column
            field="nome"
            header-text="Data"
            width="230"
            :filter="filter"
            clip-mode="EllipsisWithTooltip"
          />
          <e-column
            field="invio"
            header-text="Inviato alle bilance"
            width="200"
            :filter="filter"
            :template="templateInvio"
            clip-mode="EllipsisWithTooltip"
          />
          <e-column
            field="Azioni"
            :lock-column="true"
            header-text="Azioni"
            width="240"
            :template="cTemplate"
            :allow-editing="false"
            :commands="commands"
            :allow-sorting="false"
            :allow-filtering="false"
          />
        </e-columns>
      </ejs-grid>
      <!-- <b-row
        v-for="(rep) in pluRepositories"
        :key="rep.id"
      >
        <b-col
          cols="12"
          class="d-flex justify-content-between my-1"
        >
          <div>
            <b-badge class="mr-2">{{ rep.id }}</b-badge>
            <b-badge class="mr-2">{{ rep.nome }}</b-badge>
            <b-avatar
              :variant="rep.invio == 'S' ? 'success' : 'danger'"
              class="mr-2"
            >
              <feather-icon
                :icon="rep.invio == 'S' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>
          </div>
          <b-button
            variant="primary"
            class="btn btn-button"
            @click="(() => {
              $emit('load-plu-repository', rep.repository)
              show = false
            })"
          >
            Seleziona
          </b-button>
        </b-col>
      </b-row> -->
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable  */

import Vue from 'vue'
import {
  GridPlugin, Sort, Filter, Selection, PdfExport, ExcelExport, Edit, Page, ContextMenu, CommandColumn, Resize, Scroll,
} from '@syncfusion/ej2-vue-grids'
import {
  BModal, BBadge, BAvatar,
} from 'bootstrap-vue'
import moment from 'moment'
import { L10n, setCulture } from '@syncfusion/ej2-base'
import { italian } from '@/@core/grid-translation/it-IT'
import { getPluiRepository } from '@/@core/api-service/devices/Plu'

setCulture('it-IT')
L10n.load(italian)
Vue.use(GridPlugin)

export default {
  components: {
    BModal,
    BBadge,
    BAvatar,
  },
  props: {
    idPuntoVendita: {
      type: Number,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      options: {'weekday': 'long', 'month': '2-digit', 'day': '2-digit'},
      show: false,
      pluRepositories: [],
      commands: [
        /* { type: 'Elimina', id: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }, */
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        {
          type: 'CaricaPrezzi', id: 'CaricaPrezzi', target: '.e-column', buttonOption: { iconCss: 'e-icons e-download', cssClass: 'e-flat' },
        },
      ],
      contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
        'Copy', 'Save',
        'FirstPage', 'PrevPage',
        'LastPage', 'NextPage', 'Group', 'Ungroup',
        {
          text: 'CaricaPrezzi', target: '.e-content', id: 'CaricaPrezzi', iconCss: 'e-icons e-download',
        },
        /* {
          text: 'Elimina', tooltipText: 'Delete', iconCss: 'e-icons e-delete', id: 'DeleteCustom',
        }, */
      ],
      pageSettings: { pageSize: 25, pageSizes: [25, 50, 100, 200], pageCount: 5 },
      showModalSettingPdfExport: false,
      editSettings: {
        allowFiltering: true, allowEditing: false, allowAdding: true, allowDeleting: true, mode: 'Normal',
      },
      toolbar: [
        /* {
          text: 'Elimina', tooltipText: 'Elimina', prefixIcon: 'e-delete', id: 'Elimina',
        }, */
        ],
      toolbarOptions: ['ExcelExport'],
      filterOptions: {
        type: 'Menu',
      },
      filter: {
        type: 'CheckBox',
      },
      selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
      templateInvio() {
        return {
          template: Vue.component('templateInvio', {
            components: {
              BAvatar,
            },
            data() { return { data: {} } },
            template: `
            <b-avatar
              :variant="data.invio == 'S' ? 'success' : 'danger'"
              class="mr-2"
            >
              <feather-icon
                :icon="data.invio == 'S' ? 'CheckIcon' : 'XIcon'"
                size="14"
              />
            </b-avatar>`,
          }),
        }
      },
      cTemplate() {
        return {
          template: Vue.component('cTemplate', {
            template: `
              <div class="image">
                <img :src="image" :alt="altImage"/>
              </div>`,
            data() {
              return {
                data: {},
              }
            },
          }),
        }
      },
    }
  },
  mounted() {
    /* moment.updateLocale('it', {
      months: 'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split('_'),
      monthsShort: 'Genn._Feb._Mar_Apr._Mag_Giu_Lug._Ago_Set._Ott._nov._dic.'.split('_'),
      monthsParseExact: true,
      weekdays: 'domenica_lunedi_martedi_mercoledi_giovedi_venerdi_sabato'.split('_'),
      weekdaysShort: 'din._lun._mar._mer._gio._ven._sab.'.split('_'),
    }) */
  },
  methods: {
    openModal() {
      this.show = true
      moment.updateLocale('it', {
        months: 'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split('_'),
        monthsShort: 'Genn._Feb._Mar_Apr._Mag_Giu_Lug._Ago_Set._Ott._nov._dic.'.split('_'),
        monthsParseExact: true,
        weekdays: 'domenica_lunedi_martedi_mercoledi_giovedi_venerdi_sabato'.split('_'),
        weekdaysShort: 'din._lun._mar._mer._gio._ven._sab.'.split('_'),
      })
      getPluiRepository(JSON.stringify([{ id_puntovendita: this.idPuntoVendita}])).then(res => {
        // console.log(moment().subtract(2, 'days').format('DD/MM/YYYY HH:mm:ss'))
        // console.log(moment().format('DD/MM/YYYY HH:mm:ss').isBetween(moment().subtract(2, 'days').format('DD/MM/YYYY HH:mm:ss'), moment().format('DD/MM/YYYY HH:mm:ss'), 'days'))
        if (res.data.esito === 'OK') {
          this.pluRepositories = [...res.data.plu]
          this.pluRepositories = JSON.parse(JSON.stringify(this.pluRepositories))
          this.pluRepositories = this.pluRepositories.map(element => ({ ...element, nome: moment(element.nome, 'DD/MM/YYYY HH:mm:ss').add(2, 'h').format('DD/MM/YYYY HH:mm:ss'), repository: JSON.parse(element.repository) }))
          // const momentNow = moment()
          // const momentLess7Day = moment().subtract(7, 'days')
          //this.pluRepositories = this.pluRepositories.filter(el => moment(el.nome).isBetween(moment(momentLess7Day), moment(momentNow)))
          // this.pluRepositories.reverse()
        }
      }).catch(e => e)
    },
    commandColumm(args) {
      if (args.commandColumn.type === 'CaricaPrezzi') {
        this.$emit('load-plu-repository', args.rowData.repository)
        this.$refs['modal-plu-repository-list'].hide()
      }
    },
    contextMenuClick(args) {
      // console.log('contextMenuClick', args)
      if (args.item.id === 'CaricaPrezzi') {
        this.$emit('load-plu-repository', args.rowInfo.rowData.repository)
        this.$refs['modal-plu-repository-list'].hide()
      }
    },
    toolbarClick(args) {
      if (args.item.id === 'Elimina' && this.$refs.overviewgridRepository.getSelectedRecords().length > 0) {
        // console.log('delete')
        // this.alertAndConfirm()
      }
    },
    dataBound() {
      // console.log(this.$refs.overviewgridRepository)
      this.$refs.overviewgridRepository.autoFitColumns(['Azioni'])
    },
    close() {
      this.show = false
    },
  },
  provide: {
    grid: [PdfExport, ExcelExport, Filter, Selection, Sort, Edit, Page, ContextMenu, CommandColumn, Resize, Scroll, Filter],
  },
}
</script>

<style>

</style>
